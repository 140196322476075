import request from '@/utils/request'

//内容管理 - 资讯条数
export function informationNum(data) {
  return request({
    url: '/system/serverInformation/informationNum',
    method: 'GET',
    params: data
  })
}

//内容管理 - 资讯首页一览
export function informationPage(data) {
  return request({
    url: '/system/serverInformation/informationPage',
    method: 'post',
    data
  })
}
//报告数据一览
export function reportlist(data) {
  return request({
    url: '/system/companyReport/listPage',
    method: 'post',
    data
  })
}
//批量设置报告类型---通用版
export function updateReportGeneralVersion(data) {
  return request({
    url: '/system/companyReport/updateGeneralVersion',
    method: 'post',
    data
  })
}
//批量设置报告类型---通用版
export function reportSave(data) {
  return request({
    url: '/system/companyReport/save',
    method: 'post',
    data
  })
}

//内容管理 - 编辑 --> 资讯详情
export function selectById(data) {
  return request({
    url: '/system/serverInformation/selectById',
    method: 'GET',
    params: data
  })
}

//报告详情
export function reportById(data) {
  return request({
    url: '/system/companyReport/getReportById',
    method: 'GET',
    params: data
  })
}
//报告编辑
export function reportUpdate(data) {
  return request({
    url: '/system/companyReport/update',
    method: 'POST',
    data
  })
}
//加入已选
export function insertSelectedCompany(data) {
  return request({
    url: '/system/companyReport/insertSelectedCompany',
    method: 'POST',
    data
  })
}
//匹配企业数据一览
export function reportMatchingCompanyList(data) {
  return request({
    url: '/system/companyReport/matchingCompanyList',
    method: 'POST',
    data
  })
}
//已选企业数据一览
export function reportSelectedCompanyPage(data) {
  return request({
    url: '/system/companyReport/selectedCompanyPage',
    method: 'POST',
    data
  })
}
//发布已选
export function releaseCompany(data) {
  return request({
    url: '/system/companyReport/releaseCompany',
    method: 'POST',
    data
  })
}
//移除已选
export function deleteReleaseCompany(data) {
  return request({
    url: '/system/companyReport/deleteReleaseCompany',
    method: 'POST',
    data
  })
}
//

// //内容管理 - 编辑 -->   文件上传公共方法
// export function apiUpload(data) {
//   return request({
//     url: 'public/apiUpload',
//     method: 'POST',
//     data
//   })
// }

//内容管理 - 编辑 -->   单文件上传
export function apiUpload(data) {
  return request({
    url: '/system/public/apiUpload',
    method: 'POST',
    data
  })
}

//内容管理 - 编辑 -->   多文件上传
export function uploadFile(data) {
  return request({
    url: '/system/public/uploadFile',
    method: 'POST',
    data
  })
}
//导入报告数据
export function importReport(data) {
  return request({
    url: '/system/companyReport/importReport',
    method: 'POST',
    data
  })
}

//内容管理 - 编辑 --> 标签管理   --> 资讯标签类别
export function getInformationLabelType(data) {
  return request({
    url: '/system/companyInformation/getInformationLabelType',
    method: 'get',
    params: data
  })
}

// //内容管理查询标签
// export function getLabel() {
//   return request({
//     url: '/system/label/getLabel',
//     method: 'get'
//   })
// }

//内容管理查询标签
export function getLabel(query) {
  return request({
    url: '/system/label/getMoudulesLabel',
    method: 'get',
    params: query
  })
}

//查询子标签
export function getChild(query) {
  return request({
    url: '/system/label/getChild',
    method: 'get',
    params: query
  })
}

//内容管理 查询资讯标签详情
export function getBylabelId(query) {
  return request({
    url: '/system/serverInformation/getById',
    method: 'get',
    params: query
  })
}

//内容管理 保存标签
export function savelabel(data) {
  return request({
    url: '/system/serverInformation/insertInformationLabel',
    method: 'post',
    data
  })
}

//内容管理 - 编辑 --> 标签管理   --> 编辑资讯标签
export function updateInformationLabel(data) {
  return request({
    url: '/system/serverInformation/updateInformationLabel',
    method: 'post',
    data
  })
}

//内容管理 - 编辑 --> 标签管理   --> 资讯标签详情
export function getById(data) {
  return request({
    url: '/system/companyInformation/getById',
    method: 'get',
    params: data
  })
}

//内容管理  -   --> 匹配企业数据
export function matchingCompanyList(data) {
  return request({
    url: '/system/serverInformation/matchingCompanyList',
    method: 'post',
    data
  })
}

//内容管理 -  把匹配出来的企业与资讯关联 --> 加入已选
export function insertCorrelation(data) {
  return request({
    url: '/system/serverInformation/insertCorrelation',
    method: 'post',
    data
  })
}

//内容管理 -  确认已选的企业数据
export function matchingCompanyLists(data) {
  return request({
    url: '/system/serverInformation/selectedCompanyPage',
    method: 'post',
    data
  })
}

//内容管理 -  把匹配出来的企业与资讯移除
export function deleteCorrelation(data) {
  return request({
    url: '/system/companyInformation/deleteCorrelation',
    method: 'post',
    data
  })
}

//内容管理 -  资讯编辑
export function update(data) {
  return request({
    url: '/system/serverInformation/update',
    method: 'post',
    data
  })
}

//内容管理 -  资讯新增
export function save(data) {
  return request({
    url: '/system/serverInformation/save',
    method: 'post',
    data
  })
}

//内容管理 -  根据主键编号删除资讯
export function deleteById(data) {
  return request({
    url: '/system/companyInformation/deleteById',
    method: 'get',
    params: data
  })
}

//内容管理 -  根据关键字获取所有服务商
export function getFacilitator(data) {
  return request({
    url: '/system/companyInformation/getFacilitator',
    method: 'get',
    params: data
  })
}

//内容管理 -  根据关键字获取所有发布机构/人 名称
export function getIssueName(data) {
  return request({
    url: '/system/serverInformation/getIssueName',
    method: 'get',
    params: data
  })
}

//内容管理 -  发布已选
export function insertInformationCompany(data) {
  return request({
    url: '/system/serverInformation/matchingCompanyLists',
    method: 'post',
    data
  })
}
//内容管理 -  删除企业与资讯的推送关系 也就是不在小程序上显示
export function informationDeleteCorrelation(data) {
  return request({
    url: '/system/serverInformation/deleteCorrelation',
    method: 'post',
    data
  })
}
//内容管理 -  删除企业与资讯的推送关系 也就是不在小程序上显示
export function updateBind(data) {
  return request({
    url: '/system/companyInformation/updateBind',
    method: 'post',
    data
  })
}
//查询出所有的服务商/产品资讯
export function getInformationAssociationVo(query) {
  return request({
    url: '/system/serverInformation/getInformationAssociationVo',
    method: 'get',
    params: query
  })
}
//查询出所有的专家
export function getSpecialistAssociationVo(query) {
  return request({
    url: '/system/serverInformation/getSpecialistAssociationVo',
    method: 'get',
    params: query
  })
}
// 查询所有的智参企业
export function getSmartCompanylistAssociationVo(query) {
  return request({
    url: '/system/serverInformation/getCompanyIdList',
    method: 'get',
    params: query
  })
}

//查询出所有技术
export function getTechnologyVo(query) {
  return request({
    url: '/system/serverInformation/getTechnologyVo',
    method: 'get',
    params: query
  })
}


//批量设置资讯类型---通用版
export function updateGeneralVersion(data) {
  return request({
    url: '/system/serverInformation/updateGeneralVersion',
    method: 'post',
    data
  })
}
//新增报告标签
export function updateReportLabel(data) {
  return request({
    url: '/system/companyReport/updateReportLabel',
    method: 'post',
    data
  })
}
//报告标签详情
export function getByIdrepert(query) {
  return request({
    url: '/system/companyReport/getById',
    method: 'get',
    params: query
  })
}
//新增报告标签
export function insertInformationLabel(data) {
  return request({
    url: '/system/companyReport/insertInformationLabel',
    method: 'post',
    data
  })
}
//批量设置资讯下架
export function updateOffShelf(data) {
  return request({
    url: '/system/serverInformation/updateOffShelf',
    method: 'post',
    data
  })
}
//批量设置资讯上架
export function updateOnShelf(data) {
  return request({
    url: '/system/serverInformation/updateOnShelf',
    method: 'post',
    data
  })
}
//查询智选企业
export function getCompanyList(query) {
  return request({
    url: '/system/serverInformation/getCompanyList',
    method: 'get',
    params: query
  })
}

//文件上传签名
export function uploadsign() {
  return request({
    url: '/system/public/sign',
    method: 'get',

  })
}
//冻结报告
export function freezeReport(query) {
  return request({
    url: '/system/companyReport/freezeReport',
    method: 'get',
    params: query
  })
}
//激活报告
export function cancelFreezeReport(query) {
  return request({
    url: '/system/companyReport/cancelFreezeReport',
    method: 'get',
    params: query
  })
}
//资讯配置智参中心数据一览
export function wisdomGinsengPage(data) {
  return request({
    url: '/system/serverInformation/wisdomGinsengInformationPage',
    method: 'post',
    data
  })
}
//资讯配置智参中心下架
export function wisdomGinsengOffSelf(data) {
  return request({
    url: '/system/serverInformation/wisdomGinsengOffSelf',
    method: 'post',
    data
  })
}
//资讯配置智参中心上架
export function wisdomGinsengOnSelf(data) {
  return request({
    url: '/system/serverInformation/wisdomGinsengOnSelf',
    method: 'post',
    data
  })
}
//分页查询智参中心
export function domGinsengPage(data) {
  return request({
    url: '/system/serverInformation/wisdomGinsengPage',
    method: 'post',
    data
  })
}
//批量设置资讯类型---通用版 (未登录用户可看)
export function updateGeneralVersions(data) {
  return request({
    url: '/system/serverInformation/updateGeneralVersions',
    method: 'post',
    data
  })
}
//批量设置报告下架
export function updateOnShelfReport(data) {
  return request({
    url: '/system/companyReport/updateOffShelf',
    method: 'post',
    data
  })
}
//批量设置报告上架
export function updateOffShelfReport(data) {
  return request({
    url: '/system/companyReport/updateOnShelf',
    method: 'post',
    data
  })
}
//报告配置智参中心数据一览
export function wisdomGinsengReportPage(data) {
  return request({
    url: '/system/companyReport/wisdomGinsengReportPage',
    method: 'post',
    data
  })
}
//报告配置智参中心下架
export function wisdomGinsengOffSelfReport(data) {
  return request({
    url: '/system/companyReport/wisdomGinsengOffSelf',
    method: 'post',
    data
  })
}
//报告配置智参中心上架
export function wisdomGinsengOnSelfReport(data) {
  return request({
    url: '/system/companyReport/wisdomGinsengOnSelf',
    method: 'post',
    data
  })
}
//批量设置报告类型---通用版 (配置智参中心)
export function updateGeneralVersionReport(data) {
  return request({
    url: '/system/companyReport/updateWisdomGinseng',
    method: 'post',
    data
  })
}
//已匹配企业数据一览
export function pushedCompanyPage(data) {
  return request({
    url: '/system/serverInformation/pushedCompanyPage',
    method: 'post',
    data
  })
}
//已匹配企业--上下架
export function pushedCompanyShelf(data) {
  return request({
    url: '/system/serverInformation/pushedCompanyShelf',
    method: 'post',
    data
  })
}
//冻结资讯
export function freezeInformation(query) {
  return request({
    url: '/system/serverInformation/freezeInformation',
    method: 'get',
    params: query
  })
}
//解冻资讯
export function cancelFreezeInformation(query) {
  return request({
    url: '/system/serverInformation/cancelFreezeInformation',
    method: 'get',
    params: query
  })
}

//查询所有的标签组类别数据
export function getGroupLabel() {
  return request({
    url: '/system/label/getGroupLabel',
    method: 'get',
  })
}
//把待匹配的标签组加入到redis中进行匹配
export function setLabelToRedis(data) {
  return request({
    url: '/system/label/setLabelToRedis',
    method: 'post',
    data
  })
}
//标签池-保存已选标签
export function insertMatchLabel(data) {
  return request({
    url: '/system/label/insertMatchLabel',
    method: 'post',
    data
  })
}
//修改历史匹配标签数据
export function updateMatchedCompanyLabel(data) {
  return request({
    url: '/system/label/updateMatchedCompanyLabel',
    method: 'post',
    data
  })
}
//资讯查看人数详情页
export function listUserScanPage(data) {
  return request({
    url: '/system/serverInformation/listUserScanPage',
    method: 'post',
    data
  })
}
//资讯分享一览
export function listUserSharePage(data) {
  return request({
    url: '/system/serverInformation/listUserSharePage',
    method: 'post',
    data
  })
}
//资讯用户对接一览
export function listUserConcatPage(data) {
  return request({
    url: '/system/serverInformation/listUserConcatPage',
    method: 'post',
    data
  })
}
//报告查看一览查询
export function listreportCheckPage(data) {
  return request({
    url: '/system/companyReport/listUserCheckPage',
    method: 'post',
    data
  })
}
//报告分享一览查询
export function listreportSharePage(data) {
  return request({
    url: '/system/companyReport/listUserSharePage',
    method: 'post',
    data
  })
}

//报告下载一览查询
export function listUserDownPage(data) {
  return request({
    url: '/system/companyReport/listUserDownPage',
    method: 'post',
    data
  })
}

//已推送企业数据一览-报告
export function pushedCompanyPageReport(data) {
  return request({
    url: '/system/companyReport/pushedCompanyPageReport',
    method: 'post',
    data
  })
}
//开启免费获取报告
export function updateUserShareStatus(query) {
  return request({
    url: '/system/companyReport/updateUserShareStatus',
    method: 'get',
    params: query
  })
}
//匹配企业数据一览-人工
export function artificialMatchingCompanyList(data) {
  return request({
    url: '/system/serverInformation/artificialMatchingCompanyList',
    method: 'post',
    data
  })
}
//报告匹配企业数据一览-人工
export function reportCompanyList(data) {
  return request({
    url: '/system/companyReport/artificialMatchingCompanyList',
    method: 'post',
    data
  })
}


//=========================================================
// 因为新增了专家数据模块


//资讯配置智参中心数据一览
// export function wisdomGinsengPage(data) {
//   return request({
//     url: '/serverInformation/wisdomGinsengInformationPage',
//     method: 'post',
//     data
//   })
// }
